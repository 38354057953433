<template>
  <div class="bg-purpleShade bg-no-repeat bg-cover bg-bottom">
    <div
        class="container pb-[49px] pt-[59px] sm:py-[47px] flex justify-center flex-col items-center gap-[29px] sm:gap-[26px]"
    >
      <h2 class="text-white text-[35px]/[46px] sm:text-[47px]/[61px] font-bold font-hebrew">
        וואונדערקינד ניגון
      </h2>
      <audio-player :data="data" />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import AudioPlayer from '@components/reusable/AudioPlayer.vue'
export default defineComponent({
  components: { AudioPlayer },
  data: function () {
    return {
      data: {
        id: '1',
        mp3: '/audio/vinderkind.mp3'
      }
    }
  }
})
</script>
