<template>
    <template v-if="activeBanner">
      <div>
        <a :href="activeBanner.href" :target="activeBanner.target">
          <img
              :src="activeBanner.images.lg"
              :alt="activeBanner.alt"
              draggable="false"
              class="resp-lg w-full"
          />
          <img
              :src="activeBanner.images.md"
              :alt="activeBanner.alt"
              draggable="false"
              class="resp-md w-full"
          />
          <img
              :src="activeBanner.images.sm"
              :alt="activeBanner.alt"
              draggable="false"
              class="resp-sm w-full"
          />
        </a>
      </div>
    </template>
    <template v-else>
      <div class="bg-golden-yellow bg-no-repeate relative min-h-[350px] lg:min-h-[399px]">
        <div class="flex container" >
          <div
            class="pb-[31px] right-0 bottom-0 pr-[23px] sm:px-[56px] sm:pt-[66px] sm:pb-[94px] flex flex-col gap-3 sm:gap-[33px] z-[8] absolute sm:relative"
          >
            <h2
              class="text-vivid-purple text-[37px]/[36px] sm:text-[65px]/[60px] font-bold font-hebrew flex flex-col text-end sm:text-start"
            >
              <span> הערט,ליינט</span>
              <span>!און האט הנאה</span>
            </h2>
            <router-link
              to="/sign-up"
              class="h-[54px] justify-center bg-vivid-purple max-w-[132px] w-full py-1 flex-col items-center rounded-small px-[22px] flex transition-all duration-300 ease-in hover:opacity-70 self-end sm:self-auto"
            >
              <span class="text-lg/[23px] font-bold font-hebrew text-white">סובסקרייבט</span>
              <span class="text-[13px]/[15px] font-extrabold text-pinkish-purple">Subscribe</span>
            </router-link>
          </div>
          <img
            src="@/assets/images/home-banner.svg"
            alt="banner"
            draggable="false"
            class="absolute right-0 top-0 h-full object-cover hidden sm:block"
          />
          <img
            src="@/assets/images/home-banner-mobile.svg"
            alt="mobile banner"
            draggable="false"
            class="absolute right-0 top-0 h-full w-full object-cover block sm:hidden"
          />
        </div>
      </div>
    </template>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  components: {  },
  data: function () {
    return {
      banners: [
        {
          alt: "Banner",
          images: {
            sm: new URL('@/assets/banners/04-10-25/sm.jpg', import.meta.url).href,
            md: new URL('@/assets/banners/04-10-25/sm.jpg', import.meta.url).href,
            lg: new URL('@/assets/banners/04-10-25/lg.jpg', import.meta.url).href,
          },
          href: 'https://www.tickettailor.com/events/vinderkind/1637326',
          target: '_blank',
          between: {
            from: '2025-03-30',
            to: '2025-04-10'
          }
        }
      ]
    }
  },
  computed: {
    activeBanner() {
      const currentDate = new Date().toISOString().split('T')[0];
      return this.banners.find(banner =>
          banner.between.from <= currentDate && currentDate <= banner.between.to
      ) || null;
    }
  }
})
</script>

<style>
  .resp-lg,
  .resp-md,
  .resp-sm{
    display: none;
  }

  @media screen and (min-width: 1025px){
    .resp-lg{
      display: block;
    }
  }
  @media screen and (min-width: 640px) and (max-width: 1024px){
    .resp-md{
      display: block;
    }
  }
  @media screen and (max-width: 639px){
    .resp-sm{
      display: block;
    }
  }
</style>
